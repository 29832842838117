<template>
    <div class="content">
      <iframe class="ifm" src="https://note.magicalconch.com/eula.htm" frameborder="0"></iframe>
    </div>
  </template>
  
  <script>
  export default {
    name: 'privacy-view'
  }
  </script>
  
  <style scoped>
  .content { 
    margin: 0;
    padding: 0;  
  }  
  .ifm {
    width: calc(100vw - 20px);
    height: calc(100vh - 20px);
  }
  </style>
  