 
import { createWebHistory , createRouter } from 'vue-router'

import IndexView from '../components/Index.vue'
import PrivacyView from '../components/Privacy.vue'
import EulaView from '../components/Eula.vue'


const routes = [
    {
        path: '/',
        name: 'index',
        component: IndexView
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: PrivacyView
    }, 
    {
        path: '/eula',
        name: 'eula',
        component: EulaView
    }, 
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router