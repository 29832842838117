<template>
  <img alt="HiNote logo" src="./assets/logo.png" style="height: 200px; width: 200px;">
  
  <nav class="nav">
    <RouterLink class="nav-link"  to="/">首页</RouterLink>
    <RouterLink class="nav-link"  to="/privacy">隐私政策</RouterLink>
    <RouterLink class="nav-link" to="/eula">用户协议</RouterLink>
  </nav>
  <div class="version">v 1.1.21</div>
  <main>  
    <RouterView />
  </main>
</template>

<script> 
export default {
  name: 'App' 
}
</script>

<style>
#app { 
  text-align: center;  
  background-image: #ffffff;
  width: 100vw;
  overflow-x: hidden; 
  height: 100vh;
  margin: 0px;
  padding: 0px;
}
.nav {
  position: absolute;
  top: 33px;
  left: 10px;
  text-decoration: none; 
}
.version {
  position: absolute;
  font-size: 10px;
  top: 33px;
  right: 33px;
}
.nav-link {
  text-decoration: none;
  padding: 6px 12px;
  transition: all 0.4s; 
}
.nav-link:hover{
  background-color: #7064f3;
  transition: all 0.4s; 
  box-shadow: 2px 2px 3px #cccccc;
  color: rgb(251, 157, 173);
}
</style>
