<template>
  <div class="content">
    <div class="heard">
      <h1 style="color: #333333;  margin: -30px 0 0 0;">HiNote</h1>
      <h2 style="color: #4158D0;">Win11原生笔记本应用</h2> 
    </div>
    <div style="padding-bottom: 30px;">
      <img class="gui" src="../assets/gui1.png" style="height: 80%; width: 95%; margin-bottom: 90px; border-radius: 9px;">
    </div>
    <div class="end">
      <a target="view_window" href="https://beian.miit.gov.cn"  style="margin-right: 20px; color: #ffffff">晋ICP备17001123号</a>
      <a target="view_window" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=14082602000062" style="color: #ffffff" >晋公安网备14082602000062</a> 
    </div>
  </div>
</template>

<script>
export default {
  name: 'index-view',
  props: {
    msg: String
  }
}
</script>

<style scoped>
.content { 
  width: 100%; 
  margin: 0;
  padding: 0;
}
.heard {
  font-size: 33px;
  font-weight: 550;
}
.end {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background-color: rebeccapurple;
  height: 45px;
  display: flex;
  font-size: 12px;
  color: #ffffff;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.gui {
  transition: all 0.4s; 
}
.gui:hover {
  transition: all 0.4s; 
  box-shadow:2px 2px 5px #5346e8;
}
</style>
